document.addEventListener( 'DOMContentLoaded', function () {
  var elem = document.querySelector('.feature-carousel');

  if (elem) {
    var flkty = new Flickity( elem, {
      // options
      selectedAttraction: 0.2,
      friction: 0.8,
      autoPlay: 4500,
      wrapAround: true,
      adaptiveHeight: true,
      prevNextButtons: false
    });
  }
  
});

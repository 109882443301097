document.addEventListener( 'DOMContentLoaded', function () {


  // get the url segments
  var seg = window.location.pathname.split( '/' );

  const resultsInput = document.querySelector('.results-form input');
  const param = findGetParameter('q');

  if (resultsInput) {
    resultsInput.value = param
   }

  var urlFilters= "";

  // build some filters from any url segments.
  // it's ok if there aren't any

  // starting with the second segment

  if (seg.length < 3) {
    var urlFilters= "";
  } else {
    for (i = 2; i < seg.length; i++) {

      if ( seg[i] == "all" ) {
        seg[i] = "";
      } else {
        seg[i] = "." + seg[i];
      }
  
      urlFilters += seg[i];
      //popHeading(seg[i]);
    }

  }
  

  console.log(urlFilters)

  
  var $grid = document.querySelector('.isotope-grid');

  if ($grid) {
  
    var iso = new Isotope( $grid, {
      itemSelector: '.card-location',
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-sizer',
        gutter: '.gutter-sizer'
      }
    });

    // Filter
    (function() {

      // get references to select list and display text box
      var $selectFilters = document.querySelectorAll('#form-ui select'),
          $resultsTotal = document.getElementById('js-filter-results-total'),
          filters = {};

      // get selected option
      function getSelectedOption(sel) {
        var opt;
        for ( var i = 0, len = sel.options.length; i < len; i++ ) {
          opt = sel.options[i];
          if ( opt.selected === true ) {
            break;
          }
        }
        return opt;
      }

      // loop all selects
      for(var i=0; i<$selectFilters.length; i++) {

        // add change event to each select
        $selectFilters[i].addEventListener('change', function(event) {

            // get option form select event
            var opt = getSelectedOption(event.target);

            // get the data fitler group then the value
            var selectGroup = fizzyUIUtils.getParent( event.target, '.c-select' );
            filters[selectGroup.dataset.filterGroup] = opt.value;

            var isoFilters = [];
            for (var group in filters) {
                isoFilters.push(filters[group])
            }

            var selector = concatValues(isoFilters);
            // or
            //var selector = isoFilters.join('');

            //console.log(selector);

            iso.arrange({
                filter: selector
            });

            var total = iso.getFilteredItemElements();

            $resultsTotal.innerHTML = total.length;

          return false;

        });

      }

      if (seg.length > 2) {
        document.getElementById('location-select').value=seg[2];
        document.getElementById('service-select').value=seg[3];
      }

      iso.arrange({ filter: urlFilters });

    }());


    function concatValues( obj ) {
      var value = '';
      for ( var prop in obj ) {
        value += obj[ prop ];
      }
      return value;
    }


    

  }

  function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}



});
